<div class="container-fluid h-100 py-3">
  <div class="row py-4 justify-content-center" *ngIf="loading">
    <div class="col-auto">
      <i class="fa-light fa-spinner spinning"></i>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row animate__animated animate__bounceInDown">
      <div class="col col-12 col-sm-8 col-xxl-4 offset-sm-2 offset-xxl-4 p-2 text-center">
        <div class="rounded-circle d-inline-block" style="width: 8rem; height: 8rem">
          <hiji-user-avatar class="mb-2" style="width: 7rem; height: 7rem" [idUser]="idUser" [avatarExtension]="avatarExtension"></hiji-user-avatar>
        </div>
        <input style="display: none" id="file" type="file" accept="image/png,image/jpeg,image/gif" (change)="handleFileInput($event)" #fileInput />
        <br />
        @if (!authService.modules.frontConfiguration.hideHomeEncouragePictureCard) {
          <div>
            <button class="btn btn-secondary" (click)="fileInput.click()">
              {{ 'my-profile.modifier la photo de profil' | translate }}
              <i class="fa-light fa-edit ms-2"></i>
            </button>
            <i
              class="fa-light fa-trash ms-2 text-danger-hover"
              ngbTooltip="{{ 'my-profile.Supprimer ma photo de profil' | translate }}"
              (click)="deletePhoto()"
              *ngIf="avatarExtension !== null"
            ></i>
          </div>
          <div class="my-fs-1 mt-1">
            {{ 'my-profile.Recommandation : Pour améliorer la visibilité de votre photo, privilégiez une image carrée' | translate }}
          </div>
        }

        <div class="progress" *ngIf="progressPercent">
          <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
        </div>
        <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
      </div>
    </div>

    <div class="row mt-3 animate__animated animate__bounceInUp">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
        <div class="container-fluid py-2">
          <div class="row bg-white rounded-3 p-2 text-center shadow-1">
            <div class="col">
              <div class="my-fs-4">{{ profile.firstName }} {{ profile.lastName }}</div>
              <div class="my-fs-4">{{ profile.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 animate__animated animate__bounceInUp">
      <div class="col col-12 col-sm-10 col-xxl-6 offset-sm-1 offset-xxl-3">
        <div class="container-fluid bg-white rounded-3 py-3 shadow-1">
          <div class="row" *ngIf="displayFunctionFront">
            <div class="col">
              <div class="my-fs-4 fw-bold">
                {{ 'my-profile.Fonctions' | translate }}
              </div>
              <div class="my-fs-4">{{ profile.fonctionName }}</div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="displayFunctionFront">
            <div class="col">
              <div *ngIf="displayFunctionFront">
                <hr />
              </div>
            </div>
          </div>
          <div class="row" [class.mt-3]="!displayFunctionFront">
            <div class="col">
              <div class="my-fs-4 fw-bold">
                {{ 'my-profile.Organisation' | translate }}
              </div>
              <div class="my-fs-4 mt-3" style="white-space: pre-wrap">
                <ng-template let-node="node" #tmplNode>
                  <li>
                    <hiji-organisation-icon [type]="node.type"></hiji-organisation-icon>
                    {{ node.name }}
                    <ul *ngFor="let child of getNodesFor(node.idOrganisation)">
                      <ng-container *ngTemplateOutlet="tmplNode; context: { node: child }"></ng-container>
                    </ul>
                  </li>
                </ng-template>
                <ul *ngFor="let node of getNodesFor(0)">
                  <ng-container *ngTemplateOutlet="tmplNode; context: { node: node }"></ng-container>
                </ul>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!hideHierarchyInMyProfile">
            <div class="row mt-3">
              <div class="col">
                <hr />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="my-fs-4 fw-bold mb-3">
                  {{ profile.managers.length === 1 ? ('my-profile.Mon Manager' | translate) : ('my-profile.Mes Managers' | translate) }}
                </div>
                <div class="my-fs-4" *ngFor="let manager of profile.managers | orderBy: 'lastName'">{{ manager.firstName }} {{ manager.lastName }}</div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="my-fs-4 fw-bold mb-3">
                  {{ 'my-profile.Mes collaborateurs' | translate }}
                </div>
                <div class="my-fs-4" *ngFor="let collab of managed | orderBy: 'lastName'">
                  <a routerLink="/user/{{ collab.idUser }}/{{ string_to_slug(collab.firstName + '-' + collab.lastName) }}">
                    {{ collab.firstName }} {{ collab.lastName }}{{ isShared(collab) ? ' *' : '' }}
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-1" *ngIf="profile.managedShared.length > 0">
              <div class="col my-fs-1">* {{ 'my-profile.Collaborateurs indirects' | translate }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="showContactHelp">
      <div class="col my-fs-2 text-center">
        <a class="text-info" routerLink="/contact/profile">{{ 'my-profile.Si votre profil est incorrect ou incomplet, contactez-nous !' | translate }}</a>
      </div>
    </div>
  </ng-container>
</div>
