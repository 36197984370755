import { Component, Input, OnInit } from '@angular/core';
import { DateUtils, VizInquiryDTO } from 'common';

@Component({
    selector: 'hiji-visualize-campaign360-card',
    templateUrl: './visualize-campaign360-card.component.html',
    styleUrls: ['./visualize-campaign360-card.component.scss'],
    standalone: false
})
export class VisualizeCampaign360CardComponent implements OnInit {
  @Input()
  inquiry: VizInquiryDTO;
  DateUtils: typeof DateUtils = DateUtils;

  constructor() {}

  ngOnInit(): void {}
}
