import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { CriteriaType, SurveyObservableGestureDTO, SurveyVisualizeDTO, VisualizeSurveyService, getValuesForCriteria } from 'common';
import { AnswerModel, TextAnswerModel } from '../../../components/observable-gesture-result/AnswerModel';

@Component({
    selector: 'hiji-visualize-survey',
    templateUrl: './visualize-survey.component.html',
    styleUrls: ['./visualize-survey.component.scss'],
    standalone: false
})
export class VisualizeSurveyComponent implements OnInit {
  loading: boolean = true;
  data: SurveyVisualizeDTO;

  constructor(
    private route: ActivatedRoute,
    private visualizeSurveyService: VisualizeSurveyService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.visualizeSurveyService.getSurveyResults(this.route.snapshot.params['idCampaign']).subscribe(
      (data) => {
        this.loading = false;
        this.data = data;
      },
      (err) => {
        L.e(err);
        this.router.navigate(['/']);
      }
    );
  }

  computeAnswers(observableGesture: SurveyObservableGestureDTO): AnswerModel[] {
    const answers: AnswerModel[] = [];
    const criteria = this.getCriteria(observableGesture.idCriteria);
    if (criteria.type === CriteriaType.QUESTION_OUVERTE) return [];

    let values = getValuesForCriteria(criteria);

    for (const criteriaValue of values) {
      answers.push({
        value: criteriaValue,
        answers: [
          {
            name: this.translate.instant('global.Auto-observation'),
            color: 'var(--hiji-auto-observation)',
            nb: observableGesture.observed && observableGesture.value === criteriaValue ? 1 : 0,
          },
        ],
      });
    }
    return answers;
  }

  computeTextAnswers(observableGesture: SurveyObservableGestureDTO): TextAnswerModel[] {
    const criteria = this.getCriteria(observableGesture.idCriteria);
    if (criteria.type === CriteriaType.QUESTION_OUVERTE) {
      return [
        {
          text: observableGesture.text,
          name: null,
          color: 'var(--hiji-auto-observation)',
        },
      ];
    } else {
      return [];
    }
  }

  getCriteria(idCriteria: number) {
    return this.data.criterias.find((cr) => cr.idCriteria === idCriteria);
  }

  isInFuture(date: Date) {
    if (date === null) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate > now;
  }
}
