import { Component, Input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, HijiBundle, ModulesDTO, UserActionsDTO } from 'common';

@Component({
    selector: 'hiji-user-actions-campaign180',
    templateUrl: './user-actions-campaign180.component.html',
    styleUrls: ['./user-actions-campaign180.component.scss'],
    standalone: false
})
export class UserActionsCampaign180Component {
  @Input()
  data: UserActionsDTO;
  @Input()
  me: boolean = false;
  string_to_slug = string_to_slug;
  modulesDTO: ModulesDTO;

  constructor(private authService: AuthService) {
    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
  }
}
