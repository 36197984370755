import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { GroupDTO, GroupService, GroupType } from 'common';

@Component({
    selector: 'hiji-group-list',
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss'],
    standalone: false
})
export class GroupListComponent implements OnInit {
  groups: GroupDTO[] = [];
  GroupType: typeof GroupType = GroupType;
  loading: boolean = true;
  searchGroups: string = '';

  constructor(private groupService: GroupService, private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    this.groupService.getGroups().subscribe(
      (data) => {
        this.loading = false;
        this.groups = data;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
  selectedGroup(group: GroupDTO) {
    //this.groupData.data = group;
    if (group.groupType !== GroupType.GROUP) {
      group.groupName = this.translate.instant('common.enum.GroupType.' + group.groupType.lib);
      this.router.navigate(['/group/', group.idGroup, +group.groupType.id, string_to_slug(group.groupName)]);
    } else this.router.navigate(['/group/', group.idGroup, +group.groupType.id, string_to_slug(group.groupName)]);
  }
}
