import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GroupDTO, GroupService, GroupType, HijiBundle, Ic2ToastrService, ModulesDTO } from 'common';
import { FeedbackData } from '../fbs/feedback.data';

@Component({
    selector: 'hiji-group-actions',
    templateUrl: './group-actions.component.html',
    styleUrls: ['./group-actions.component.scss'],
    standalone: false
})
export class GroupActionsComponent implements OnInit {
  @Input() group: GroupDTO;
  loading: boolean = true;
  GroupType: typeof GroupType = GroupType;
  string_to_slug = string_to_slug;
  modulesDTO: ModulesDTO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private groupService: GroupService,
    private feedbackdata: FeedbackData,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService
  ) {
    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.groupService.getGroup(GroupType.mapFromId(parseInt(this.route.snapshot.params['typeGroup'])), this.route.snapshot.params['idGroup']).subscribe(
        (data) => {
          this.loading = false;
          this.group = data;
        },
        (err) => {
          this.loading = false;
          this.router.navigate(['/']);
          if (err.isBusinessError()) this.ic2ToastrService.showDanger(this.translate.instant("group.actions.Cette équipe n'existe pas"));
          L.e(err);
        }
      );
    });
  }

  editAGroup(idGroup) {
    // ROUTER LINK  ET a
  }
  deleteAGroup(idGroup) {
    this.groupService.deleteGroup(idGroup).subscribe(
      (data) => {
        this.router.navigate(['/groups']);
      },
      (err) => {
        L.e(err);
      }
    );
  }
  goToFeedback() {
    this.feedbackdata.reset();
    this.feedbackdata.users = this.group.users;
    this.feedbackdata.groupSelected = this.group;
    this.router.navigate(['/feedback/new']);
  }
}
