import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { EnvService, L, MsPipe, MultilingualstringConfigService, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ClientEnv, Config, HijiBundle, HijiRight, ModulesDTO, ProfileService } from 'common';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocaleLoader } from '../LocaleLoader';

@Component({
    selector: 'hiji-front-layout',
    templateUrl: './front-layout.component.html',
    styleUrls: ['./front-layout.component.scss'],
    standalone: false
})
export class FrontLayoutComponent implements OnInit, OnDestroy {
  show: boolean = false;
  title: string = '';
  titleIconClass: string = null;
  titleImg: string = null;
  _routerSub: Subscription;
  showFrontLanguageChange: boolean;
  currentLang: string;
  boUrl;
  canSeeBackoffice = false;
  idUser: number;
  avatarExtension;
  hideDiscover: boolean = false;
  hideProgress: boolean = false;
  hideVisualize: boolean = false;
  sortedAvailableLanguages: string[];
  isDarkMode: boolean;
  showBackLink: boolean = false;
  modules: ModulesDTO;
  HijiRight = HijiRight;
  string_to_slug = string_to_slug;

  constructor(
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public multilingualstringConfigService: MultilingualstringConfigService,
    public env: EnvService<Config>,
    public clientEnv: ClientEnv,
    public profileService: ProfileService,
    private msPipe: MsPipe
  ) {
    this.currentLang = translate.currentLang || translate.defaultLang;
    translate.onLangChange.subscribe((evt) => {
      this.currentLang = evt.lang;
    });
    this.canSeeBackoffice = authService.has(HijiRight.ADMIN, HijiRight.GESTIONNAIRE);
    this.showFrontLanguageChange = authService.modules.moduleGlobal.allowUserToChangeLanguage;
    this.boUrl = env.configuration.boUrl;
    this.idUser = this.authService.userBundle.user.idUser;
    this.avatarExtension = (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension;
    this.modules = authService.modules;
    this.hideDiscover = authService.modules.frontConfiguration.hideDiscoverMenu;
    this.hideProgress = authService.modules.frontConfiguration.hideProgressMenu;
    this.hideVisualize = authService.modules.frontConfiguration.hideVisualizeMenu ?? false;
    this.sortedAvailableLanguages = this.multilingualstringConfigService.availableLanguages.sort((a, b) =>
      this.translate.instant('common.languages.' + a).localeCompare(this.translate.instant('common.languages.' + b))
    );
    this.showBackLink = history.length > 0;
  }

  ngOnInit(): void {
    this._routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.showBackLink = true;
        this.handleRouteData(route);
      });

    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    this.handleRouteData(currentRoute.snapshot);

    if (localStorage && localStorage.getItem('DarkMode') === null) {
      this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
      if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    }
    if (localStorage && localStorage.getItem('DarkMode') === 'false') this.isDarkMode = false;
    if (localStorage && localStorage.getItem('DarkMode') === 'true') this.isDarkMode = true;
    this.applyTheme();
  }

  handleRouteData(route: ActivatedRouteSnapshot) {
    let currentRoute = route;
    let found = false;
    do {
      if (currentRoute.data.title) {
        this.title = currentRoute.data.title;
        if (currentRoute.data.title === 'menu.ema') this.title = this.msPipe.transform(this.modules.moduleEMA.emaName);
        found = true;
      }
      if (currentRoute.data.img) this.titleImg = currentRoute.data.img;
      if (currentRoute.data.icon) this.titleIconClass = currentRoute.data.icon;

      if (found) break;
    } while ((currentRoute = route.parent) !== null);

    if (!found) {
      this.title = '';
      this.titleIconClass = null;
      this.titleImg = null;
    }
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }

  toggleMenu() {
    this.show = !this.show;
  }
  hideMenu() {
    this.show = false;
  }

  hasModuleEnabled(module: string) {
    const hijiBundle = this.authService.userBundle.data as HijiBundle;
    if (module === 'campaign' && hijiBundle.modules.module180.enabled) return true;
    if (module === 'campaign360' && hijiBundle.modules.module360.enabled) return true;
    if (module === 'fbs' && hijiBundle.modules.moduleFBS.enabled) return true;

    return false;
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.profileService.updateLocale(language).subscribe(
      (data) => {
        LocaleLoader.load(language).then(() => {
          this.translate.use(language);
          this.currentLang = this.translate.currentLang || this.translate.defaultLang;
          this.sortedAvailableLanguages = this.multilingualstringConfigService.availableLanguages.sort((a, b) =>
            this.translate.instant('common.languages.' + a).localeCompare(this.translate.instant('common.languages.' + b))
          );
        });
        // window.location.href = window.location.href; //reload non obligatoire coté front car pas de multilingual input
      },
      (err) => {
        L.e(err);
      }
    );
  }

  logout() {
    this.authService.logout();
    localStorage.setItem('forcelogin', 'true');
    this.router.navigateByUrl('/logout');
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) localStorage.setItem('DarkMode', 'true');
    if (!this.isDarkMode) localStorage.setItem('DarkMode', 'false');
    this.applyTheme();
  }

  private applyTheme() {
    if (localStorage.getItem('DarkMode') === 'true') {
      document.body.parentElement.classList.add('dark-mode');
    } else {
      document.body.parentElement.classList.remove('dark-mode');
    }
  }
}
