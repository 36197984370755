import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ClientEnv, Ic2ToastrService, UserFuncAvatarDTO, UserSearchService } from 'common';
import { FeedbackData } from '../fbs/feedback.data';

@Component({
    selector: 'hiji-search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss'],
    standalone: false
})
export class SearchUserComponent implements OnInit {
  loading: boolean = true;
  users: UserFuncAvatarDTO[] = [];
  string_to_slug = string_to_slug;
  searchSlug: string = null;
  displayFunctionFront: boolean = false;

  constructor(
    private userSearchService: UserSearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private feedbackData: FeedbackData,
    private authService: AuthService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService,
    public clientEnv: ClientEnv
  ) {}

  ngOnInit(): void {
    this.searchSlug = this.activatedRoute.snapshot.params['slug'];
    this.loading = false;
    this.displayFunctionFront = this.authService.modules.frontConfiguration.displayFunctionFront;
  }

  userClick(user: UserFuncAvatarDTO): void {
    if (this.searchSlug === 'sollicitation-fbs') {
      const u = new UserFuncAvatarDTO();
      u.idUser = user.idUser;
      u.avatarExtension = user.avatarExtension;
      u.firstName = user.firstName;
      u.lastName = user.lastName;
      u.functionName = user.functionName;
      this.feedbackData.reset();
      this.feedbackData.users = [u];
      this.router.navigate(['/feedback/ask']);
    } else if (this.searchSlug === 'send-fbs') {
      const u = new UserFuncAvatarDTO();
      u.idUser = user.idUser;
      u.avatarExtension = user.avatarExtension;
      u.firstName = user.firstName;
      u.lastName = user.lastName;
      u.functionName = user.functionName;
      this.feedbackData.reset();
      this.feedbackData.users = [u];
      this.router.navigate(['/feedback/new']);
    } else {
      this.router.navigate(['/user/', user.idUser, string_to_slug(user.firstName + '-' + user.lastName)]);
    }
  }

  searchUser(event) {
    if (event.target.value.length > 0) {
      this.userSearchService.searchUser(event.target.value, false).subscribe(
        (data) => {
          this.loading = false;
          this.users = data;
        },
        (err) => {
          this.loading = false;
          if (err.isBusinessError() && err.data && err.data['code'] === 'no_enseigne')
            this.ic2ToastrService.show(
              this.translate.instant(
                "search-user.Vous n'appartenez pas à une enseigne et ne pouvez pas faire de recherche d'utilisateurs, contactez l'assistance"
              ),
              {
                classname: 'bg-warning text-white',
              }
            );
          L.e(err);
        }
      );
    } else {
      this.users = [];
    }
  }
}
