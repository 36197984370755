import { Component, Input, OnInit } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, HijiBundle, TeamMemberDTO, VizSurveyDTO } from 'common';

@Component({
    selector: 'hiji-visualize-survey-card',
    templateUrl: './visualize-survey-card.component.html',
    styleUrls: ['./visualize-survey-card.component.scss'],
    standalone: false
})
export class VisualizeSurveyCardComponent implements OnInit {
  @Input()
  user: TeamMemberDTO;
  string_to_slug = string_to_slug;
  @Input()
  survey: VizSurveyDTO;
  avatarExtension;
  constructor(public authService: AuthService) {
    this.avatarExtension = (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension;
  }

  ngOnInit(): void {}
}
